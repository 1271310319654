import * as React from "react"
import Head from "next/head"
import type { AppProps } from "next/app"
import Script from "next/script"
import "react-calendar/dist/Calendar.css"
import "rc-slider/assets/index.css"
import "react-day-picker/dist/style.css"
import "glider-js/glider.min.css"
import "styles/index.scss"
import "ui-kit/styles-branded/index.scss"
import "styles/button/buttons.scss"
import "components/SocialAvatars/social-avatars.scss"
import "components/BrowserWarningBanner/browser-warning-banner.scss"
import "components/UserAvatar/styles.scss"
import "components/Header/components/MobileNavigation/mobile-navigation.scss"
import "components/Header/components/DesktopNavigation/desktop-navigation.scss"
import "components/Header/header-user-picture.scss"
import "components/Header/page-header.scss"
import "components/PsychologistAvatar/psychologist-avatar.scss"
import "components/SupportDescription/SupportDescription.scss"
import "components/Price/price-and-discount.scss"
import "components/Tabs/tabs.scss"
import "components/BulletList/bullet-list.scss"
import "components/Footer/footer.scss"
import "components/ProfileCard/style.scss"
import "components/CertificateBlock/CertificateBlock.scss"
import "components/CollapseIcon/collapse-icon.scss"
import "components/NotificationBanner/notification-banner.scss"
import "components/PhoneInput/styles.scss"
import "components/CourseBanner/style.scss"
import "components/RecommendToEmployerBanner/style.scss"
import "components/FAQ/faq.scss"
import "components/Spinner/spinner.scss"
import "components/DownloadLinks/style.scss"
import "components/VideoCallLocation/style.scss"
import "components/ClubBanner/styls.scss"
import "components/AdviceBlock/style.scss"
import "styles/profile.scss"
import "styles/search.scss"
import "styles/strike.scss"
import "styles/booking.scss"
import "styles/google_signin.scss"
import "styles/psy-account.scss"
import "modules/PsychologistAccount/components/Booking/index.scss"
import "modules/PsychologistAccount/PaymentsReportsTab/date-picker.scss"
import "modules/PsychologistAccount/SettingsTab/styles.scss"
import "modules/PsychologistAccount/SettingsTab/components/PsyIndexQuality/styles.scss"
import "modules/PsychologistAccount/SettingsTab/components/PsyIndexQuality/components/PieChart/styles.scss"
import "modules/PsychologistAccount/SettingsTab/components/TelegramAccount/AttachTelegram/styles.scss"
import "modules/PsychologistAccount/Header/styles.scss"
import "modules/PsychologistAccount/HeaderQualityLabel/styles.scss"
import "modules/PsychologistAccount/BookingDetails/style.scss"
import "modules/PsychologistAccount/ClientsFeedbackTab/style.scss"
import "modules/SelectionOfPsychologists/survey-results.scss"
import "modules/Booking/index.scss"
import "modules/BookingCommon/style.scss"
import "modules/discount/header-discount-badge.scss"
import "modules/discount/discount.scss"
import "pages/NotFoundPage/not-found-page.scss"
import "modules/ClientAuthorization/client-authorization.scss"
import "modules/HRAccount/HR-account.scss"
import "components/PersonalInfoText/personal-info-text.scss"
import "pages/FreePsysPage/styles.scss"
import "modules/Stripe/styles.scss"
import "modules/PsyCatalog/common/PromotedPsys/style.scss"
import "modules/Call/components/styles.scss"
import "modules/Call/components/GoToCallButton/style.scss"
import "modules/Call/components/PageError/style.scss"
import "modules/ClientAccount/index.scss"

import { INCLUDE_PROMO_CODE_BANNER_JS, PROMO_CODE_BANNER_SCRIPT_URL } from "utils/env-management"

function CustomApp({ Component, pageProps }: AppProps) {
    return (
        <>
            <Head>
                <title>Подбор психолога - Alter</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
            </Head>
            {INCLUDE_PROMO_CODE_BANNER_JS && (
                <Script
                    src={PROMO_CODE_BANNER_SCRIPT_URL}
                    onLoad={() => window.showPromoCodeBanner?.("app")}
                />
            )}
            <Component {...pageProps} />
        </>
    )
}

export default CustomApp
