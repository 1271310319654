import { useState, useEffect } from "react"
import dynamic from "next/dynamic"
import Head from "next/head"
import { useRouter } from "next/router"

import { APP_PAGE_URL } from "utils/env-management"

const MainEntryPoint = dynamic(() => import("../src/index"), { ssr: false })

function Index() {
    const [isMounted, setIsMounted] = useState(false)
    const { asPath } = useRouter()

    useEffect(() => {
        setIsMounted(true)
    }, [])

    const enableIframeDemo = process.env.DEV_SERVER && asPath.includes("/iframe")

    if (enableIframeDemo) {
        return (
            <>
                <iframe
                    src="http://localhost:3000/?iframe=1"
                    frameBorder="0"
                    style={{
                        border: "none",
                        width: "100vw",
                        height: "100vh",
                        overflow: "hidden",
                    }}
                />
            </>
        )
    }

    return (
        <>
            <Head>
                <meta name="description" content="Автоматический подбор психолога по анкете" />
                <meta property="og:url" content={`${APP_PAGE_URL}/survey/psy`} />
                <meta property="og:title" content="Подбор психолога - Alter" />
                <meta
                    property="og:description"
                    content="Автоматический подбор психолога по анкете"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content="https://static.tildacdn.com/tild6339-3765-4433-b830-613664383434/bg_vk_fb.png"
                />
            </Head>
            {isMounted && <MainEntryPoint />}
        </>
    )
}

export default Index
